import * as React from "react"
import { Fade, makeStyles, Slide } from "@material-ui/core"
import BannerLeft from "./banner-left"
import BannerRight from "./banner-right"
import { useState } from "react"
import VizSensor from "react-visibility-sensor"

const useStyles = makeStyles((theme) => ({
  divAll: {
    // backgroundImage: "linear-gradient(to right, #00B0B3 , #00B790)",
    width: "100%",
    height: "auto",
    paddingTop: 25,
    paddingBottom: 25
  },
  content: {
    width: "100%",
    maxWidth: 1200,
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(1000)]: {
      flexWrap: "wrap"
    }
  },
  half: {
    width: "50%",
    [theme.breakpoints.down(1000)]: {
      width: "100%"
    }
  },
  paper: {
    backgroundImage: `url("/images/wave_bg_6.jpg")`,
    transition: "background 5s linear",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    paddingTop: 120,
    paddingBottom: 60
  }
}))

const SendMessageBanner = ({ isPaper, isConnectionPage = false }) => {
  const classes = useStyles()
  const [fadeIn, setFadeIn] = useState(false)

  return <div className={`${classes.divAll} ${isPaper ? classes.paper : ""}`}>
    <VizSensor
      onChange={(isVisible) => {
        if (!fadeIn) {
          setFadeIn(isVisible)
        }
      }}
    >
    <div className={classes.content}>
      <Slide direction="right" in={true} timeout={1000}>
        <div className={classes.half}>
          <Fade in={true} timeout={2000}>
            <div><BannerLeft blackChars={isPaper} isConnectionPage={isConnectionPage} /></div>
          </Fade>
        </div>
      </Slide>
      <Slide direction="left" in={true} timeout={1000}>
        <div className={classes.half}>
          <Fade in={true} timeout={2000}>
            <div style={{marginTop: '10px'}}><BannerRight isPaper={isPaper} /></div>
          </Fade>
        </div>
      </Slide>
    </div>
    </VizSensor>
  </div>
}

export default SendMessageBanner
