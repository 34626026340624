import * as React from "react"
import { Link, makeStyles, useMediaQuery } from "@material-ui/core"
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail"
import PhoneIcon from "@material-ui/icons/Phone"
import HomeIcon from "@material-ui/icons/Home"
import TeamMemberCard from "../team-intro/team-member-card"
import CallToAction from "../call-to-action/call-to-action"

const useStyles = makeStyles((theme) => ({
  divAll: {
    width: "95%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    margin: "auto",
    [theme.breakpoints.down(600)]: {
      width: "80%"
    }
  },
  divRows: {
    width: "100%",
    paddingTop: 20
  },
  divRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingTop: 15,
    justifyContent: "space-between",
    [theme.breakpoints.down(530)]: {
      flexWrap: "wrap",
      justifyContent: "center",
      paddingTop: 18
    }
  },
  divRowTitle: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 5,
    fontWeight: "bold",
    minWidth: 110
  },
  divRowValue: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(530)]: {
      minWidth: 300,
      justifyContent: "center"
    }
  },
  grow: {
    width: 30
  },
  divTitle: {
    marginBottom: '15px',
    fontSize:'27px'
  },
  divImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: '15px'
  }
}))

const BannerLeft = ({ blackChars = false, isConnectionPage = false }) => {
  const classes = useStyles()
  const small = useMediaQuery('(max-width:600px)');

  return <div className={classes.divAll} style={blackChars ? { color: "black" } : { color: "white" }}>
    <div style={{ width: "100%", paddingBottom: 20 }}>
      {!isConnectionPage && <div className={classes.divImg}>
        <img src="/images/alpha_form.svg" alt={"alphaws"} height={small ? 100 : 170} width={'100%'}/>
      </div>}
      {!isConnectionPage && <div>
        Vegye fel velünk a kapcsolatot!<br />
        Az üzenet elküldése után 72 órán belül jelentkezni fogunk.
      </div>}
      {isConnectionPage && <div className={classes.divTitle}>
        Felkeltettük az érdeklődésedet?
      </div>}
      {isConnectionPage && <div>
        Vedd fel velünk a kapcsolatot!<br />
        Az üzenet elküldése után 72 órán belül jelentkezni fogunk.
      </div>}
    </div>
    <div className={classes.divRows}>
      <div className={classes.divRow}>
        <div className={classes.divRowTitle}><AlternateEmailIcon />
          <div style={{ paddingLeft: 3 }}> E-mail:</div>
        </div>
        <div className={classes.divRowValue}>info[kukac]alphapro.hu</div>
      </div>
      <div className={classes.divRow}>
        <div className={classes.divRowTitle}><PhoneIcon />
          <div style={{ paddingLeft: 3 }}>Telefon:</div>
        </div>
        <div className={classes.divRowValue}>
          <Link href="tel: 06 70 907 23 33"
                style={blackChars ? { color: "black" } : { color: "white" }}
                underline="none">
            06 70 / 907 23 33
          </Link>
        </div>
      </div>
    </div>
    <div className={classes.divRow}>
      <div className={classes.divRowTitle}><HomeIcon />
        <div style={{ paddingLeft: 3 }}>Címünk:</div>
      </div>
      <div className={classes.divRowValue}>
        1139 Budapest, Frangepán utca 46.
      </div>
    </div>
    {isConnectionPage && <CallToAction noButton/>}
  </div>
}

export default BannerLeft
