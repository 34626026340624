import * as React from "react"
import { makeStyles, Paper } from "@material-ui/core"
import SendMessageForm from "../form/send-message-form"
import SendMessagePaperForm from "../form/send-message-paper-form"

const useStyles = makeStyles((theme) => ({
  divAll: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    [theme.breakpoints.down(1000)]: {
      paddingTop: 15
    }
  },
}));

const BannerRight = ({isPaper}) => {
  const classes = useStyles();

  return <div className={classes.divAll}>
    {isPaper? <SendMessagePaperForm/> : <SendMessageForm/>}
  </div>
}

export default BannerRight
