import * as React from "react"
import { useState } from "react"
import { Button, Checkbox, FormControlLabel, makeStyles, Paper, TextField, Typography } from "@material-ui/core"
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail"
import PhoneIcon from "@material-ui/icons/Phone"
import PersonIcon from "@material-ui/icons/Person"
import ChatIcon from "@material-ui/icons/Chat"
import { send } from 'emailjs-com';
import { navigate } from "gatsby"

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "80%",
    borderRadius: 25,
    padding: 15
  },
  divForm: {
    background: "rgba(255, 255, 255, 0.2)",
    padding: 25,
    borderRadius: 25
  },
  divRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10
  },
  divRowIcon: {
    paddingRight: 10,
    width: "15%"
  },
  divRowField: {
    width: "85%"
  },
  input: {
    backgroundColor: "white"
  },
  divCheckboxAndButton: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 30,
    paddingBottom: 10
  },
  divCheckbox: {
    width: "60%"
  },
  divButton: {
    width: "40%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    backgroundImage: "linear-gradient(to right, #27A1B9 , #47AA6B)",
    borderRadius: 25,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 50,
    paddingRight: 50,
    color: "white",
    transition: "all 0.2s",
    "&:hover": {
      backgroundColor: "#303B56",
      transform: "perspective(1px) scale(1.05)"
    },
    textTransform: "none"
  },
  divImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  img: {
    width: 70,
    height: 22,
    marginBottom: 0
  },
  aszfLabel: {
    fontSize: 13
  },
  chatIconRoot: {
    alignSelf: "flex-start"
  }
}))

const SendMessagePaperForm = () => {
  const classes = useStyles()
  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [mobilePhone,setMobilePhone] = useState('');
  const [message,setMessage] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    send(
      'service_k2n6bj5',
      'template_z4gw8mx',
      {
        name: name,
        email: email,
        mobilePhone: mobilePhone,
        message: message,
      },
      'user_pqGIrsqeuBQqvJitpknJf'
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        navigate('/sikeres-kapcsolatfelvetel').then(r => {})

      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
  };

  return <Paper className={classes.paper} elevation={3}>
    <div className={classes.divImg}>
      <img src="/images/xo_icons_2.png" className={classes.img} alt={"XO"} />
    </div>
    {/*<form action="https://getform.io/f/0150f869-1172-437f-b47a-2c1b68dfdaaf" method="POST">*/}
    <form onSubmit={onSubmit}>
      <div className={classes.divForm}>
        <div className={classes.divRow}>
          <div className={classes.divRowIcon}><PersonIcon color="primary" /></div>
          <div className={classes.divRowField}>
            <TextField variant="outlined"
                       placeholder="Név*"
                       size="small"
                       name="name"
                       fullWidth
                       InputProps={{
                         className: classes.input
                       }}
                       value={name}
                       onChange={e => setName( e.target.value )}
            />
          </div>
        </div>
        <div className={classes.divRow}>
          <div className={classes.divRowIcon}><AlternateEmailIcon color="primary" /></div>
          <div className={classes.divRowField}>
            <TextField variant="outlined"
                       placeholder="E-mail cím*"
                       size="small"
                       name="email"
                       fullWidth
                       InputProps={{
                         className: classes.input
                       }}
                       value={email}
                       onChange={e => setEmail( e.target.value )}
            />
          </div>
        </div>
        <div className={classes.divRow}>
          <div className={classes.divRowIcon}><PhoneIcon color="primary" /></div>
          <div className={classes.divRowField}>
            <TextField variant="outlined"
                       placeholder="Telefonszám*"
                       name="mobilePhone"
                       size="small"
                       fullWidth
                       InputProps={{
                         className: classes.input
                       }}
                       value={mobilePhone}
                       onChange={e => setMobilePhone( e.target.value )}
            />
          </div>
        </div>
        <div className={classes.divRow}>
          <div className={`${classes.divRowIcon} ${classes.chatIconRoot}`}><ChatIcon color="primary" /></div>
          <div className={classes.divRowField}>
            <TextField variant="outlined"
                       placeholder="Üzenet írása..."
                       size="small"
                       name="message"
                       fullWidth
                       multiline
                       InputProps={{
                         className: classes.input
                       }}
                       rows={3}
                       value={message}
                       onChange={e => setMessage( e.target.value )}
            />
          </div>
        </div>
        <div className={classes.divCheckboxAndButton}>
          <div className={classes.divButton}>
            <Button className={classes.button} variant="contained" type="submit">
              Elküldöm
            </Button>
          </div>
        </div>
      </div>
    </form>
  </Paper>
}

export default SendMessagePaperForm
